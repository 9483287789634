<template>
    <div>
      <h3 class="text-left">Merchants</h3>
      <v-text-field
      v-model="searchTerm"
      label="Search Merchants"
      single-line
      clearable
    ></v-text-field>
      <v-table>
      <thead>
        <tr>
          <th class="text-left">Merchant Name</th>
          <th class="text-left">Creation Date</th>
          <th class="text-left">Sales Person</th>
          <th class="text-left">Status</th>
          <th class="text-left">Actions</th> 
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredMerchants" :key="item.id" class="merchant-row">
          <td class="text-left" @click="openMerchantDialog(item, $event)">{{ item.name }}</td>
          <td class="text-left" @click="openMerchantDialog(item, $event)">{{ item.created }}</td>
          <td class="text-left" @click="openMerchantDialog(item, $event)">{{ item.sales_person }}</td>
          <td class="text-left" @click="openMerchantDialog(item, $event)">{{ item.status }}</td>
        
          <td class="text-left">
            <div class="action-buttons">
                <!-- Download Bank Letter Button -->
                <v-btn small color="primary" @click.stop="downloadBankStatement(item)">
                  Download Bank Letter
                </v-btn>

                <!-- Tag Sales Person Button -->
                <v-btn small color="secondary" @click.stop="tagSalesAssociate(item)">
                  Tag Sales Person
                </v-btn>

                <!-- New buttons -->
                <v-btn small color="green" @click.stop="openTerminalIdDialog(item)">
                  Set Terminal ID
                </v-btn>
                <v-btn small color="orange" @click.stop="openCloudKeyDialog(item)">
                  Set Cloud Key
                </v-btn>

                <v-btn small color="info" @click.stop="openProxyDialog(item)">
                  Attach Proxy
                </v-btn>

               <!-- <v-btn small color="purple" @click.stop="openPandL(item)">
                  P&L
                </v-btn> -->

          </div>
          </td>
        </tr>
      </tbody>
    </v-table>


<!-- Full Screen Dialog -->
    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-card-title>
          <br >
          <h3>{{ currentMerchant.name }}</h3>
          <v-spacer></v-spacer>
          <br >
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-timeline side="end" align="start">
    <v-timeline-item
      dot-color="purple"
      size="small"
    >
      <div class="d-flex">
        <strong class="me-4">2023-10-01 5:10pm</strong>
        <div>
          <strong>Account Created</strong>
          <div class="text-caption">
            Web App
          </div>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
      dot-color="yellow"
      size="small"
    >
      <div class="d-flex">
        <strong class="me-4">2023-10-01 5:32PM</strong>
        <div>
          <strong>Account Details Updated</strong>
          <div class="text-caption mb-2">
            Step 2
          </div>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
      dot-color="pink"
      size="small"
    >
      <div class="d-flex">
        <strong class="me-4">2023-10-01 6:05pm</strong>
        <div>
          <strong>Account Details Updated</strong>
          <div class="text-caption">
            Step 3
          </div>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
      dot-color="blue"
      size="small"
    >
      <div class="d-flex">
        <strong class="me-4">2023-10-01 7:09PM</strong>
        <div>
          <strong>Application Submitted To Tilled</strong>
          <div class="text-caption">
            Step 5
          </div>
        </div>
      </div>
    </v-timeline-item>

    <v-timeline-item
      dot-color="green"
      size="small"
    >
      <div class="d-flex">
        <strong class="me-4">2023-10-01 7:20PM</strong>
        <div>
          <strong>Application Approved</strong>
          <div class="text-caption">
            Merchant Active
          </div>
        </div>
      </div>
    </v-timeline-item>
  </v-timeline>
      </v-card>
    </v-dialog>



        <!-- Attach Proxy Dialog -->
    <v-dialog v-model="proxyDialog" persistent max-width="300px">
      <v-card>
        <v-card-title class="text-h5">Attach Proxy</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newProxy"
            label="Proxy Number"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="proxyDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="submitNewProxy">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Profit and Loss Dialog -->
    <v-dialog v-model="pandlDialog" persistent max-width="800px" >
        <v-card>
          <v-card-title class="text-h5">Select Start & End Dates</v-card-title>
          <v-card-text>
            <v-row>
             
              <!-- Start Date Picker Column -->
           

              <!-- End Date Picker Column -->
              <v-col cols="12" md="6">
                <VueDatePicker v-model="date" range></VueDatePicker>
              </v-col>
            </v-row>

            <!-- Card for Revenue -->
            <v-card outlined class="my-2">
              <v-card-title>Revenue</v-card-title>
              <v-card-text>{{ revenueForPeriod }}</v-card-text>
            </v-card>

            <!-- Card for Profit -->
            <v-card outlined class="my-2">
              <v-card-title>Profit</v-card-title>
              <v-card-text>{{ profitForPeriod }}</v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="pandlDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>



     <!-- Tag Sales Person Dialog -->
  <v-dialog v-model="tagDialog" persistent max-width="300px">
    <v-card>
      <v-card-title class="text-h5">Tag Sales Person</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newSalesPerson"
          label="Sales Person Name"
          outlined
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="tagDialog = false">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="submitNewSalesPerson">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>



  <!-- Set Terminal ID Dialog -->
<v-dialog v-model="terminalIdDialog" persistent max-width="300px">
  <v-card>
    <v-card-title class="text-h5">Set Terminal ID</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="newTerminalId"
        label="Terminal ID"
        outlined
        dense
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="terminalIdDialog = false">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="submitNewTerminalId">OK</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

  <!-- Set Cloud Key Dialog -->
  <v-dialog v-model="cloudKeyDialog" persistent max-width="300px">
    <v-card>
      <v-card-title class="text-h5">Set Cloud Key</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newCloudKey"
          label="Cloud Key"
          outlined
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cloudKeyDialog = false">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="submitNewCloudKey">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>




    </div>
  </template>
  
  <script>

  import axios from 'axios';
  export default {
    data () {
        return {
        searchTerm: '',
        allMerchants: [],
        tagDialog: false,
        newSalesPerson: '',
        date : null,
        selectedMerchant: null,
        dialog: false,
        currentMerchant: null,
        terminalIdDialog: false,
        newTerminalId: '',
        cloudKeyDialog: false,
        newCloudKey: '',
        proxyDialog: false,
        pandlDialog:false,
        newProxy: '',
        selectedDate: [],
        startDate: null,
        endDate: null,
        revenueForPeriod: 0,
        profitForPeriod: 0,
        }},

        computed: {
          filteredMerchants() {
            if (!this.searchTerm) return this.allMerchants;
            return this.allMerchants.filter(merchant => {
              const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
              const name = merchant.name ? merchant.name.toLowerCase() : '';
              const email = merchant.email ? merchant.email.toLowerCase() : '';
              const status = merchant.status ? merchant.status.toLowerCase() : '';
              const salesPerson = merchant.sales_person ? merchant.sales_person.toLowerCase() : '';

              return name.includes(lowerCaseSearchTerm) ||
                    email.includes(lowerCaseSearchTerm) ||
                    status.includes(lowerCaseSearchTerm) ||
                    salesPerson.includes(lowerCaseSearchTerm);
            });
          },
        },

        watch: {
        // Watchers for startDate and endDate
        startDate(newVal) {
          if (newVal && this.endDate) {
            this.calculateRevenueAndProfit();
          }
        },
        endDate(newVal) {
          if (newVal && this.startDate) {
            this.calculateRevenueAndProfit();
          }
        },
        date(){
          this.calculateRevenueAndProfit();
        }
      },

     
        created() {
          this.fetchMerchants();
        },
        methods: {

            openProxyDialog(merchant) {
              this.selectedMerchant = merchant;
              this.newProxy = ''; // Reset or set the current proxy
              this.proxyDialog = true;
            },
            openPandL(merchant) {
              console.log("Opening P&L Dialog");
              this.selectedMerchant = merchant;
              this.pandlDialog = true;
              this.startDate = null;
              this.endDate = null;
            },

            // Method to calculate revenue and profit for the selected period
            calculateRevenueAndProfit() {
              // Implement your logic to calculate revenue and profit
              // based on this.startDate and this.endDate
              // For example:
              this.revenueForPeriod = 1000; // Replace with actual calculation
              this.profitForPeriod = 500; // Replace with actual calculation
            },
           openTagDialog(merchant) {
              this.selectedMerchant = merchant;
              this.newSalesPerson = ''; // Reset or set to the current sales person's name
              this.tagDialog = true;
            },

            async submitNewSalesPerson() {
              try {
                const merchantId = this.selectedMerchant.id;
                const url = `${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/client/${merchantId}`;
                const data = {
                  application_id: "",
                  status: "",
                  pricing_option: "",
                  business_name: "",
                  legal_name: "",
                  business_type: "",
                  tax_identification_number: "",
                  business_phone: "",
                  company_email: "",
                  company_phone: "",
                  company_name: "",
                  average_transaction_amount: 0,
                  yearly_volume_range: "",
                  business_category: "",
                  statement_descriptor: "",
                  street: "",
                  street2: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  account_number: "",
                  routing_number: "",
                  principal_id: "",
                  principal_first_name: "",
                  principal_last_name: "",
                  principal_date_of_birth: "1800-01-01",
                  tax_id: "",
                  average_transaction: "",
                  yearly_transaction: "",
                  interac_accepted: "",
                  statement_name: "",
                  address: "",
                  province: "",
                  postal_code: "",
                  owner_first_name: "",
                  owner_last_name: "",
                  owner_phone: "",
                  owner_job_title: "",
                  owner_percentage_owned: 0,
                  owner_years_at_address: 0,
                  owner_ssn: "",
                  owner_dob: "1800-01-01",
                  principal_job_title: "",
                  principal_percentage_shareholding: 0,
                  principal_ssn: "",
                  principal_phone: "",
                  principal_street: "",
                  principal_street2: "",
                  principal_city: "",
                  principal_state: "",
                  principal_zip: "",
                  sales_person: this.newSalesPerson, // Dynamic field
                  principal_years_at_address: 0
                };


                await axios.put(url, data);
                console.log('Sales person updated:', this.newSalesPerson);

                // Optionally update the local data to reflect the change
                this.selectedMerchant.sales_person = this.newSalesPerson;
                this.tagDialog = false;

                // Refresh the merchants list or re-fetch the data if needed
              } catch (error) {
                console.error('Error updating sales person:', error);
                // Handle the error appropriately
              }

              this.tagDialog = false;
            },
            async fetchMerchants() {
            try {
              const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/v1/admin/merchants`);
              if (response.data && response.data.status === 'success') {
                this.allMerchants = response.data.data.map(merchant => ({
                  name: merchant.company_name,
                  created: merchant.created_at,
                  email:merchant.email_address,
                  id:merchant.id,
                  status: merchant.status,
                  application_id:merchant.application_id,
                  cloud_key: merchant.cloud_key,
                  terminal_id:merchant.terminal_id,
                  sales_person: merchant.sales_person, // Assuming you'll add this info later
                  // ... include other properties you need from the merchant object ...
                })).sort((a, b) => {
                // Example sorting by 'created' date
                return new Date(a.created) - new Date(b.created);
              });
              }
            } catch (error) {
              console.error('Error fetching merchants:', error);
              // Handle the error appropriately
            }
          },
          openMerchantDialog(merchant, event) {
          // Check if the clicked element is not part of the action button
          if (!event.target.closest('.action-button')) {
            this.currentMerchant = merchant;

            const url = `https://app.tilled.com/merchants/${merchant.application_id}`;
            window.open(url, '_blank'); // '_blank' opens the URL in a new tab
            
          }
        },
        tagSalesAssociate(merchant) {
          console.log(merchant);
          this.selectedMerchant = merchant;
          this.newSalesPerson = merchant.sales_person;
          this.tagDialog = true;
        },

        async submitNewProxy() {
          try {
            const merchantId = this.selectedMerchant.id;
            const url = `${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/client-proxy/${merchantId}`;
            const data = {
              proxy: this.newProxy
            };

            await axios.post(url, data);
            console.log('Proxy attached:', this.newProxy);

            // Optionally update local data or re-fetch
            this.proxyDialog = false;
          } catch (error) {
            console.error('Error attaching proxy:', error);
            // Handle the error appropriately
          }
        },
        async downloadBankStatement(merchant) {
              console.log(merchant);
              try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/client/bank-letter/${merchant.email}`;

                // Making the request for the PDF file
                const response = await axios.get(url, { responseType: 'blob' });

                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'BankLetter.pdf'); // You can use a dynamic name if preferred
                document.body.appendChild(fileLink);
                
                fileLink.click(); // Trigger the download

                fileLink.remove(); // Clean up
                window.URL.revokeObjectURL(fileURL); // Free up resources
              } catch (error) {
                console.error('Error downloading bank statement:', error);
                // Handle the error appropriately
              }
         },
          openTerminalIdDialog(merchant) {
          this.selectedMerchant = merchant;
          this.newTerminalId = merchant.terminal_id; // Reset the field
          this.terminalIdDialog = true;
        },

        async submitNewTerminalId() {
          try {
            await axios.post(`${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/client-terminal-id`, {
              email_address: this.selectedMerchant.email,
              terminal_id: this.newTerminalId,
            });
            console.log('Terminal ID updated for:', this.selectedMerchant.email);
            this.terminalIdDialog = false;
            this.fetchMerchants();
          } catch (error) {
            console.error('Error updating terminal ID:', error);
          }
        },

        openCloudKeyDialog(merchant) {
          console.log(merchant);
          this.selectedMerchant = merchant;
          this.newCloudKey = merchant.cloud_key; // Reset the field
          this.cloudKeyDialog = true;
        },

        async submitNewCloudKey() {
          try {
            await axios.post(`${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/client-cloud-key`, {
              email_address: this.selectedMerchant.email,
              cloud_key: this.newCloudKey,
            });
            console.log('Cloud Key updated for:', this.selectedMerchant.email);
            this.cloudKeyDialog = false;
            this.fetchMerchants();
          } catch (error) {
            console.error('Error updating cloud key:', error);
          }
        },
         onActionButtonClick() {
          
          // You can add more logic here if needed, for example, setting the current merchant
        },
      },
      name: 'MerchantsPage'
    }
  </script>

<style>
/* CSS to change cursor to pointer on hover over table rows */
.merchant-row:hover {
  cursor: pointer;
}
.action-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: left;
  gap: 4px; /* Space between buttons */
  margin: 10px;
}

.action-buttons .v-btn {
  width: 200px; /* Adjust width as needed */
  height: 20; /* Adjust height as needed */
  padding: 0px; /* Adjust padding as needed */
  font-size: 0.8rem; /* Adjust font size as needed */
}
</style>
  