<template>
  <div class="dashboard">
    <v-card class="mx-auto" min-width="200">
      <div class="sidebar-container">
        <!-- Sidebar List -->
        <v-list>
          <img width="40" src="https://assets-global.website-files.com/64838113327f8bace4dcf2d2/64905788189d0e9f85e81679_faster_logo_final.png"/>

          <br />
          <br />
       
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <!-- Logout Button at the bottom -->
        <v-list-item class="logout-button" link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </div>
    </v-card>

    <main class="content">
      <router-view />
    </main>
  </div>
</template>
  
  <style scoped>
  .dashboard {
    display: flex;
    
    height: 100vh; /* Full viewport height */
  }
  
  .sidebar-container {
  display: flex;
  
  flex-direction: column;
  height: 100%;
}


  .sidebar {
    width: 300px;
   
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }
  
  
  .logout-button {
  margin-top: auto; /* Pushes the logout button to the bottom */
}
.content {
  flex-grow: 1;
  padding: 20px;
 /* Add margin equal to the width of the sidebar */
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable scrolling for the content */
}
  </style>

<script>
export default {
  data: () => ({
    items: [
      { text: 'Home', icon: 'mdi-clock', to: '/dashboard/home' },
      { text: 'Merchants', icon: 'mdi-store', to: '/dashboard/merchants' },
      { text: 'Transactions', icon: 'mdi-cash-fast',to: '/dashboard/payouts' },
      //{ text: 'Commissions & Payout ', icon: 'mdi-cash',to: '/dashboard/commissions' },
    ],
  }),
  methods: {
    logout() {
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>
  