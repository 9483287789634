<template>
    <div class="login-container">
      <img width="70" src="https://assets-global.website-files.com/64838113327f8bace4dcf2d2/64905788189d0e9f85e81679_faster_logo_final.png"/>
      <h2>Faster Admin Login</h2>
      <form @submit.prevent="submitLogin">
        <div>
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="loginDetails.email_address" required>
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="loginDetails.password" required>
        </div>
        <v-btn type="submit" :loading="isLoading" color="primary">
          Login
          <v-progress-circular indeterminate color="white" size="20" right v-if="isLoading" />
        </v-btn>
      </form>
      <Popup
      title="Login Error"
      message="Unable to login. Please check your credentials."
      v-model="showPopup"
    />
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Popup from './PopUp.vue'

  export default {
    name: 'AdminLogin',
    components: {
    Popup
  },

    data() {
      return {
        showPopup: false,
        isLoading: false,
        loginDetails: {
          email_address: '',
          password: ''
        }
      }
    },
    methods: {
      async submitLogin() {
        this.isLoading = true;
        try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/v1/admin/login`, this.loginDetails);
        if (response.status === 200) {
          console.log('Login Successful', response.data);
          this.$router.push({ name: 'Dashboard' });
        }
      } catch (error) {
        console.error('Login Failed', error);
        this.showPopup = true;
        this.loginError = true;
      }
      finally {
        this.isLoading = false; // Stop loading
      }



      
        // Here you would handle the login logic, like sending a request to your server
      }
    }
  }
  </script>
  
  <style scoped>
 .login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

form div {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=email], input[type=password] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

  </style>
  