import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue';
import AdminLogin from '../components/AdminLogin.vue';

import DashboardPage from '../components/DashboardPage.vue';
import HomePage from '../components/HomePage.vue';
import MerchantsPage from '../components/MerchantsPage.vue';
import PayoutsPage from '../components/PayoutsPage.vue';
import CommissionsPage from '../components/Commissions.vue';
import OTPPage from '../components/OTP.vue';


const routes = [

  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: '/dashboard/home',
    component: DashboardPage,
    children: [
      { path: 'home', component: HomePage },
      { path: 'merchants', component: MerchantsPage },
      { path: 'payouts', component: PayoutsPage },
      { path: 'commissions', component: CommissionsPage }
    ]
  },
  {
    path: '/',
    redirect: '/login',
    name: 'Login',
  },
  {
    path: '/login',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/hello',
    name: 'HelloWorld',
    component: HelloWorld
  },
  {
    path: '/otp',
    name: 'OTP',
    component: OTPPage
  }
  // ... any other routes
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
