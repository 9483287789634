<template>

<div class="login-container">
      <img width="70" src="https://assets-global.website-files.com/64838113327f8bace4dcf2d2/64905788189d0e9f85e81679_faster_logo_final.png"/>
      <br>
      <br>
      <h2>Please enter 2FA Code</h2>
      <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6">
            <v-otp-input
          v-model="otp"
          length="6"
          @submit="submitOtp"
        ></v-otp-input>
        <v-btn type="submit" :loading="isLoading" color="primary" @click="submitOtp">
            Submit
          <v-progress-circular indeterminate color="white" size="20" right v-if="isLoading" />
        </v-btn>
        
        </v-col>
      </v-row>
    </v-container>
      <Popup
      title="OTP Error"
      message="Unable to login. Please check your OTP code."
      v-model="showPopup"
    />
    </div>
    
  </template>
  
  <script>
  export default {
    name: 'OtpPage',
    data() {
      return {
        otp: ''
      };
    },
    methods: {
      submitOtp() {

        this.isLoading = true;
        try{
            console.log('Submitted OTP:', this.otp);
        }
        catch(error){
            console.log(error);
        }
        finally {
        this.isLoading = false; // Stop loading
        }

       

        this.$router.push({ name: 'Dashboard' });
        // Add logic to handle the OTP submission
      }
    }
  };
  </script>

<style scoped>
.login-container {
 max-width: 700px;
 margin: 50px auto;
 padding: 20px;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



 </style>
  