<template>
    <div>
      <h3 class="text-left">Commissions & Payout</h3>
      <v-combobox
    label="Sales Person"
    v-model="selectedMerchant"
    :items="merchants"
    item-title="name"
    item-value="email"
  
  ></v-combobox>
  
  
      <!-- Process All Transactions Button -->
        <v-btn
          v-if="hasUnpaidTransactions"
          color="primary"
          @click="processAllTransactions"
        >
          Process All Transactions
        </v-btn>
      
        
      <v-data-table  item-key="name" :headers="tableHeaders"  :items="payoutData">
  
       
  
        <template v-slot:item="{ item }">
          <tr class="merchant-row">
            <td class="text-left">{{ item.amount }}</td>
            <td class="text-left">{{ item.date }}</td>
            <td class="text-left">{{ item.status }}</td>
            <td class="text-left">
              <v-btn v-if="item.status === 'unpaid'" color="primary" small @click="processPayment(item)">
                Process Payment
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table> 
  
      <v-dialog
        v-model="dialog"
       
        width="auto"
      >
        <v-card>
          <v-card-text>
           <p><b>Transaction Amount:</b> $33.55</p>
           <p><b>TimeStamp:</b> 2023-11-04T20:37:30.176Z</p>
           <p><b>Currency:</b>CAD</p>
           <p><b>Transaction ID:</b> 78215087</p>
           <p><b>Merchant Name:</b> Bromance</p>
           <p><b>Merchant ID:</b> bro20231018171307128</p>
           <p><b>Device:</b> PAXA920PRO</p>
           <p><b>Transaction Action:</b> AUTHORIZATION_REQUEST</p>
           <p><b>Transaction Approval Code:</b> 00404J</p>
           <p><b>Transaction Scenario:</b> CHIPCONTACTLESS</p>
           <p><b>Transaction Response Text:</b> APPROVAL</p>
            
  
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="dialog = false">Done</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  
  import axios from 'axios';
  export default {
    name: 'PayoutsPage',
    data() {
      return {
        selectedMerchant: '',
        dialog: false,
        merchants: [{
          name: 'Harry',
          value: '1',
        },],
        payoutData: [],
        tableHeaders: [
        {
          title: 'Amount',
          value: 'amount',
        },
        {
          title: 'Payout Date',
          value: 'date',
          sortable: true,
        },
        {
          title: 'Status',
          value: 'status',
        },
        {
          title: 'Actions',
          value: 'actions', // This is a placeholder and won't correspond to a property in your items
          sortable: false, // Assuming you don't want this column to be sortable
        },
      ],
       
      };
    },
  
      computed: {
        hasUnpaidTransactions() {
          return this.payoutData.some(tx => tx.status === 'unpaid');
        },
      },
    watch: {
    selectedMerchant(newVal, oldVal) {
      if (newVal) {
        console.log('Selected Merchant changed:', newVal.name, newVal.email, oldVal);
        this.fetchPayoutData();
      }
    },
  },
    created() {
     // this.fetchMerchants();
     
    },
    methods: {
  
  
        async processPayment(transaction) {
            try {
              // Here, replace the URL with the endpoint for processing payment
              const url = `${process.env.VUE_APP_API_BASE_URL}/v1/process-payment`;
              const response = await axios.post(url, transaction);
              
              if (response.data && response.data.status === 'success') {
                console.log('Payment processed successfully for transaction:', transaction);
                // Handle successful payment processing (e.g., update UI or show success message)
              } else {
                console.error('Payment processing failed:', response.data.message);
                // Handle failed payment processing appropriately
              }
            } catch (error) {
              console.error('Error processing payment:', error);
              // Handle network or other errors appropriately
            }
          },
  
  
      processAllTransactions() {
          this.payoutData.forEach(transaction => {
            if (transaction.status === 'unpaid') {
              // Call your method to process each transaction
              this.processPayment(transaction);
            }
          });
        },
  
      async fetchMerchants() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/v1/admin/merchants`);
            if (response.data && response.data.status === 'success') {
              this.merchants = response.data.data.map(merchant => ({
                name: merchant.company_name,
                email: merchant.email_address,
              }));
            }
          } catch (error) {
            console.error('Error fetching merchants:', error);
          }
        },
  
      openDialog() {
        //this.dialog = true;
      },
      async fetchPayoutData() {
      if (this.selectedMerchant && this.selectedMerchant.email) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/transactions/${(this.selectedMerchant.email)}`);
          if (response.data && response.data.status === 'success') {
            this.payoutData = response.data.data.map(tx => ({
              amount: `$${parseFloat(tx.amount).toFixed(2)}`,
              date: new Date(tx.timestamp).toLocaleDateString(), // Format date as needed
              status: tx.status,
             
              // You can add more fields here based on the transaction data structure
            }));
          }
        } catch (error) {
          this.payoutData = [];
          console.error('Error fetching transactions:', error);
          // Handle the error appropriately (e.g., show a notification or message)
        }
      } else {
        this.payoutData = [];
        console.log('No merchant selected or email is missing');
        // Handle the case where no merchant is selected or email is missing
      }
    },
    },
  };
  </script>
  
  
  
  <style>
  /* CSS to change cursor to pointer on hover over table rows */
  .merchant-row:hover {
    cursor: pointer;
  }
  </style>
  
  
  
  
  
  
    