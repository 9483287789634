<template>
  <div>
    <v-container class="">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-card
            class="mx-auto my-8"
            max-width="344"
            :title="'$' + current_wallet_amount"
            subtitle="Merchant Payout Wallet"
            link
          ></v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card
            class="mx-auto my-8"
            max-width="344"
            :title="'$' + unpaid_transactions"
            subtitle="Unpaid Transactions"
            link
          ></v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card
            class="mx-auto my-8"
            max-width="344"
            :title="'$' + revenue"
            subtitle="Revenue Last 30 Days"
            link
          >
          </v-card>
        </v-col>
      </v-row>

     

    </v-container>

    <v-divider></v-divider>
    <br />

    <!-- v-table outside of v-row -->
    <v-table>
      <thead>
        <tr>
          <th class="text-left">Merchant Name</th>
          <th class="text-left">Creation Date</th>
          <th class="text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in merchants" :key="item.name">
          <td class="text-left">{{ item.name }}</td>
          <td class="text-left">{{ item.created }}</td>
          <td class="text-left">{{ item.status }}</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>


  
<script>
import axios from "axios";

export default {
  name: "HomePage",
  data() {
    return {
      merchants: [],
      revenue: "-",
      unpaid_transactions:"-",
      current_wallet_amount:"-",
    };
  },
  created() {
    this.fetchMerchants();
    this.fetchRevenue();
    this.fetchUnpaidTransactions();
    this.fetchWallet();
  },
  methods: {
    async fetchWallet() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/wallet-data`
        );
        if (response.data && response.data.status === "success") {
          this.current_wallet_amount = response.data.data.wallets.current_amount;
         
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
        // Handle the error appropriately
        
      }
    },
    async fetchRevenue() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/revenue`,
          {
            start_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date().toISOString().split('T')[0]
          }
        );
        if (response.data && response.data.status === "success") {
          this.revenue = parseFloat(response.data.data).toFixed(2);
        }
      } catch (error) {
        console.error("Error fetching revenue:", error);
        // Handle the error appropriately
      }
    },
    async fetchUnpaidTransactions() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/v1/onboarding/unpaid-transactions`
        );
        if (response.data && response.data.status === "success") {
          // Process the response data here
          const unpaidTransactions = response.data.data;
          const totalAmount = unpaidTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);
          this.unpaid_transactions = totalAmount.toFixed(2);
          
        }
      } catch (error) {
        console.error("Error fetching unpaid transactions:", error);
        // Handle the error appropriately
      }
    },

    async fetchMerchants() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/v1/admin/merchants`
        );
        if (response.data && response.data.status === "success") {
          this.merchants = response.data.data.map((merchant) => ({
            name: merchant.company_name,
            created: merchant.created_at,
            status: merchant.status,
          }));
        }
      } catch (error) {
        console.error("Error fetching merchants:", error);
        // Handle the error appropriately
      }
    },
  },
};
</script>
  