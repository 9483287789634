import { createApp } from 'vue';
import App from './App.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Import Vuetify styles
import store from './store'; // Import the Vuex store
import router from './router';

import '@mdi/font/css/materialdesignicons.css';

import * as components from 'vuetify/components';


// Create a Vuetify instance
const vuetify = createVuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
    components,
    // any other Vuetify options
  });

const app = createApp(App);

app.component('VueDatePicker', VueDatePicker);

app.use(store); // Use the Vuex store
app.use(router);
app.use(vuetify); // Use Vuetify

app.mount('#app');
